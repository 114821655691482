import { graphql } from "gatsby"
import React from "react"
import PartnerCards from "../components/PartnerCards"
import PartnerList from "../components/PartnerList";
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const PartnersPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Partners" description="Ruald Strydom" />

      <div className="bg-gray-100 py-12 lg:py-16">
        <div className="container py-1 lg:pb-1">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Partners
          </h1>
        </div>
        {data.partners && data.partners.nodes.length > 0 ? (
          <PartnerCards items={data.partners.nodes} />
        ) : (
          <div className="container">No partners found.</div>
        )}
      </div>

    </Layout>
  )
}

export default PartnersPage

export const query = graphql`
      query PartnersQuery {
        partners: allContentfulPartner(sort: {fields: createdOn, order: DESC }, limit: 6) {
          nodes {
            ...PartnerCard
          }
        }
  }
      `
